@charset "utf-8";

@font-face {
	font-family: 'Noto Serif Japanese';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/font/NotoSerifCJKjp-Regular.woff') format('woff'),
		url('/assets/font/NotoSerifCJKjp-Regular.ttf')  format('truetype');
}

@font-face {
	font-family: 'Noto Serif Japanese';
	font-style: normal;
	font-weight: 500;
	src: url('/assets/font/NotoSerifCJKjp-Medium.woff') format('woff'),
	url('/assets/font/NotoSerifCJKjp-Medium.ttf')  format('truetype');
}

@font-face {
	font-family: 'Noto Serif Japanese';
	font-style: normal;
	font-weight: 600;
	src: url('/assets/font/NotoSerifCJKjp-SemiBold.woff') format('woff'),
	url('/assets/font/NotoSerifCJKjp-SemiBold.ttf')  format('truetype');
}

@font-face {
	font-family: 'Noto Serif Japanese';
	font-style: normal;
	font-weight: 700;
	src: url('/assets/font/NotoSerifCJKjp-Bold.woff') format('woff'),
	url('/assets/font/NotoSerifCJKjp-Bold.ttf')  format('truetype');
}

@font-face {
	font-family: 'Noto Serif Japanese';
	font-style: normal;
	font-weight: 900;
	src: url('/assets/font/NotoSerifCJKjp-Black.woff') format('woff'),
	url('/assets/font/NotoSerifCJKjp-Black.ttf')  format('truetype');
}


/* ===============================================

	RESET
  
=============================================== */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
	display:block;
}

h1,h2,h3,h4,h5 {
	line-height:1;
	font-size:100%;
	font-weight:normal;
}

img {
	border:none;
	vertical-align:bottom;
}

ul, ol, li, dl, dt, dd {
	list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000; 
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration:line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
	font-weight:normal;
}

caption, th, td {
	text-align:left;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;   
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, textarea, select {
    vertical-align:middle;
	font-family:inherit;
	font-size:inherit;
	font-weight:inherit;
}

address {
	font-style: normal;
}


/* ===============================================

	FONT SIZE
  
=============================================== */
body {
	font-size:13px;
	line-height:1.5;
	-webkit-text-size-adjust: none;
}
 
select, input, textarea, button {
	font-size:13px;
	-webkit-text-size-adjust: none;
}

table {
	font-size:inherit;
	font:100%;
}

sup {
	font-size:77%;
	vertical-align:top;
}



/* ===============================================

	FONT FAMILY
  
=============================================== */
body {
	font-family: "Noto Serif Japanese", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN","HG明朝E","ＭＳ Ｐ明朝","ＭＳ 明朝",serif;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
}