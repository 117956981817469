@charset "UTF-8";
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/NotoSerifCJKjp-Regular.woff") format("woff"), url("/assets/font/NotoSerifCJKjp-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/font/NotoSerifCJKjp-Medium.woff") format("woff"), url("/assets/font/NotoSerifCJKjp-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 600;
  src: url("/assets/font/NotoSerifCJKjp-SemiBold.woff") format("woff"), url("/assets/font/NotoSerifCJKjp-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 700;
  src: url("/assets/font/NotoSerifCJKjp-Bold.woff") format("woff"), url("/assets/font/NotoSerifCJKjp-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 900;
  src: url("/assets/font/NotoSerifCJKjp-Black.woff") format("woff"), url("/assets/font/NotoSerifCJKjp-Black.ttf") format("truetype"); }

/* ===============================================

	RESET
  
=============================================== */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

h1, h2, h3, h4, h5 {
  line-height: 1;
  font-size: 100%;
  font-weight: normal; }

img {
  border: none;
  vertical-align: bottom; }

ul, ol, li, dl, dt, dd {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-weight: normal; }

caption, th, td {
  text-align: left; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, textarea, select {
  vertical-align: middle;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

address {
  font-style: normal; }

/* ===============================================

	FONT SIZE
  
=============================================== */
body {
  font-size: 13px;
  line-height: 1.5;
  -webkit-text-size-adjust: none; }

select, input, textarea, button {
  font-size: 13px;
  -webkit-text-size-adjust: none; }

table {
  font-size: inherit;
  font: 100%; }

sup {
  font-size: 77%;
  vertical-align: top; }

/* ===============================================

	FONT FAMILY
  
=============================================== */
body {
  font-family: "Noto Serif Japanese", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN","HG明朝E","ＭＳ Ｐ明朝","ＭＳ 明朝",serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizeLegibility; }

.mt0 {
  margin-top: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mt1 {
  margin-top: 1px !important; }

.mb1 {
  margin-bottom: 1px !important; }

.ml1 {
  margin-left: 1px !important; }

.mr1 {
  margin-right: 1px !important; }

.mt2 {
  margin-top: 2px !important; }

.mb2 {
  margin-bottom: 2px !important; }

.ml2 {
  margin-left: 2px !important; }

.mr2 {
  margin-right: 2px !important; }

.mt3 {
  margin-top: 3px !important; }

.mb3 {
  margin-bottom: 3px !important; }

.ml3 {
  margin-left: 3px !important; }

.mr3 {
  margin-right: 3px !important; }

.mt4 {
  margin-top: 4px !important; }

.mb4 {
  margin-bottom: 4px !important; }

.ml4 {
  margin-left: 4px !important; }

.mr4 {
  margin-right: 4px !important; }

.mt5 {
  margin-top: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mt6 {
  margin-top: 6px !important; }

.mb6 {
  margin-bottom: 6px !important; }

.ml6 {
  margin-left: 6px !important; }

.mr6 {
  margin-right: 6px !important; }

.mt7 {
  margin-top: 7px !important; }

.mb7 {
  margin-bottom: 7px !important; }

.ml7 {
  margin-left: 7px !important; }

.mr7 {
  margin-right: 7px !important; }

.mt8 {
  margin-top: 8px !important; }

.mb8 {
  margin-bottom: 8px !important; }

.ml8 {
  margin-left: 8px !important; }

.mr8 {
  margin-right: 8px !important; }

.mt9 {
  margin-top: 9px !important; }

.mb9 {
  margin-bottom: 9px !important; }

.ml9 {
  margin-left: 9px !important; }

.mr9 {
  margin-right: 9px !important; }

.mt10 {
  margin-top: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mt11 {
  margin-top: 11px !important; }

.mb11 {
  margin-bottom: 11px !important; }

.ml11 {
  margin-left: 11px !important; }

.mr11 {
  margin-right: 11px !important; }

.mt12 {
  margin-top: 12px !important; }

.mb12 {
  margin-bottom: 12px !important; }

.ml12 {
  margin-left: 12px !important; }

.mr12 {
  margin-right: 12px !important; }

.mt13 {
  margin-top: 13px !important; }

.mb13 {
  margin-bottom: 13px !important; }

.ml13 {
  margin-left: 13px !important; }

.mr13 {
  margin-right: 13px !important; }

.mt14 {
  margin-top: 14px !important; }

.mb14 {
  margin-bottom: 14px !important; }

.ml14 {
  margin-left: 14px !important; }

.mr14 {
  margin-right: 14px !important; }

.mt15 {
  margin-top: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mt16 {
  margin-top: 16px !important; }

.mb16 {
  margin-bottom: 16px !important; }

.ml16 {
  margin-left: 16px !important; }

.mr16 {
  margin-right: 16px !important; }

.mt17 {
  margin-top: 17px !important; }

.mb17 {
  margin-bottom: 17px !important; }

.ml17 {
  margin-left: 17px !important; }

.mr17 {
  margin-right: 17px !important; }

.mt18 {
  margin-top: 18px !important; }

.mb18 {
  margin-bottom: 18px !important; }

.ml18 {
  margin-left: 18px !important; }

.mr18 {
  margin-right: 18px !important; }

.mt19 {
  margin-top: 19px !important; }

.mb19 {
  margin-bottom: 19px !important; }

.ml19 {
  margin-left: 19px !important; }

.mr19 {
  margin-right: 19px !important; }

.mt20 {
  margin-top: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mt21 {
  margin-top: 21px !important; }

.mb21 {
  margin-bottom: 21px !important; }

.ml21 {
  margin-left: 21px !important; }

.mr21 {
  margin-right: 21px !important; }

.mt22 {
  margin-top: 22px !important; }

.mb22 {
  margin-bottom: 22px !important; }

.ml22 {
  margin-left: 22px !important; }

.mr22 {
  margin-right: 22px !important; }

.mt23 {
  margin-top: 23px !important; }

.mb23 {
  margin-bottom: 23px !important; }

.ml23 {
  margin-left: 23px !important; }

.mr23 {
  margin-right: 23px !important; }

.mt24 {
  margin-top: 24px !important; }

.mb24 {
  margin-bottom: 24px !important; }

.ml24 {
  margin-left: 24px !important; }

.mr24 {
  margin-right: 24px !important; }

.mt25 {
  margin-top: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mt26 {
  margin-top: 26px !important; }

.mb26 {
  margin-bottom: 26px !important; }

.ml26 {
  margin-left: 26px !important; }

.mr26 {
  margin-right: 26px !important; }

.mt27 {
  margin-top: 27px !important; }

.mb27 {
  margin-bottom: 27px !important; }

.ml27 {
  margin-left: 27px !important; }

.mr27 {
  margin-right: 27px !important; }

.mt28 {
  margin-top: 28px !important; }

.mb28 {
  margin-bottom: 28px !important; }

.ml28 {
  margin-left: 28px !important; }

.mr28 {
  margin-right: 28px !important; }

.mt29 {
  margin-top: 29px !important; }

.mb29 {
  margin-bottom: 29px !important; }

.ml29 {
  margin-left: 29px !important; }

.mr29 {
  margin-right: 29px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mt31 {
  margin-top: 31px !important; }

.mb31 {
  margin-bottom: 31px !important; }

.ml31 {
  margin-left: 31px !important; }

.mr31 {
  margin-right: 31px !important; }

.mt32 {
  margin-top: 32px !important; }

.mb32 {
  margin-bottom: 32px !important; }

.ml32 {
  margin-left: 32px !important; }

.mr32 {
  margin-right: 32px !important; }

.mt33 {
  margin-top: 33px !important; }

.mb33 {
  margin-bottom: 33px !important; }

.ml33 {
  margin-left: 33px !important; }

.mr33 {
  margin-right: 33px !important; }

.mt34 {
  margin-top: 34px !important; }

.mb34 {
  margin-bottom: 34px !important; }

.ml34 {
  margin-left: 34px !important; }

.mr34 {
  margin-right: 34px !important; }

.mt35 {
  margin-top: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mt36 {
  margin-top: 36px !important; }

.mb36 {
  margin-bottom: 36px !important; }

.ml36 {
  margin-left: 36px !important; }

.mr36 {
  margin-right: 36px !important; }

.mt37 {
  margin-top: 37px !important; }

.mb37 {
  margin-bottom: 37px !important; }

.ml37 {
  margin-left: 37px !important; }

.mr37 {
  margin-right: 37px !important; }

.mt38 {
  margin-top: 38px !important; }

.mb38 {
  margin-bottom: 38px !important; }

.ml38 {
  margin-left: 38px !important; }

.mr38 {
  margin-right: 38px !important; }

.mt39 {
  margin-top: 39px !important; }

.mb39 {
  margin-bottom: 39px !important; }

.ml39 {
  margin-left: 39px !important; }

.mr39 {
  margin-right: 39px !important; }

.mt40 {
  margin-top: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mt41 {
  margin-top: 41px !important; }

.mb41 {
  margin-bottom: 41px !important; }

.ml41 {
  margin-left: 41px !important; }

.mr41 {
  margin-right: 41px !important; }

.mt42 {
  margin-top: 42px !important; }

.mb42 {
  margin-bottom: 42px !important; }

.ml42 {
  margin-left: 42px !important; }

.mr42 {
  margin-right: 42px !important; }

.mt43 {
  margin-top: 43px !important; }

.mb43 {
  margin-bottom: 43px !important; }

.ml43 {
  margin-left: 43px !important; }

.mr43 {
  margin-right: 43px !important; }

.mt44 {
  margin-top: 44px !important; }

.mb44 {
  margin-bottom: 44px !important; }

.ml44 {
  margin-left: 44px !important; }

.mr44 {
  margin-right: 44px !important; }

.mt45 {
  margin-top: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mt46 {
  margin-top: 46px !important; }

.mb46 {
  margin-bottom: 46px !important; }

.ml46 {
  margin-left: 46px !important; }

.mr46 {
  margin-right: 46px !important; }

.mt47 {
  margin-top: 47px !important; }

.mb47 {
  margin-bottom: 47px !important; }

.ml47 {
  margin-left: 47px !important; }

.mr47 {
  margin-right: 47px !important; }

.mt48 {
  margin-top: 48px !important; }

.mb48 {
  margin-bottom: 48px !important; }

.ml48 {
  margin-left: 48px !important; }

.mr48 {
  margin-right: 48px !important; }

.mt49 {
  margin-top: 49px !important; }

.mb49 {
  margin-bottom: 49px !important; }

.ml49 {
  margin-left: 49px !important; }

.mr49 {
  margin-right: 49px !important; }

.mt50 {
  margin-top: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

/* float */
.float_r {
  float: right !important; }

.float_l {
  float: left !important; }

.float_none {
  float: none !important; }

/* text */
.txt_r {
  text-align: right !important; }

.txt_l {
  text-align: left !important; }

.txt_c {
  text-align: center !important; }

/* hover */
.on-all a {
  opacity: 1;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }

.on-all a:hover {
  opacity: .7; }

.on {
  opacity: 1;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }

.on:hover {
  opacity: .7; }

/* clearfix*/
.cf:before,
.cf:after {
  content: "";
  display: block;
  overflow: hidden; }

.cf:after {
  clear: both; }

/* pc-sp */
.switch_sp {
  display: none !important; }
  @media screen and (max-width: 767px) {
    .switch_sp {
      display: block !important;
      margin: 0 auto; } }

.switch_pc {
  display: block !important;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .switch_pc {
      display: none !important; } }

body {
  width: 100%;
  background: #fff;
  font-size: 14px;
  color: #000;
  text-align: center; }
  body img {
    max-width: 100%; }

.header {
  height: 125px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 145px; }
  .header_inner {
    position: relative;
    width: 1120px;
    margin: 0 auto; }
  .header_logo {
    width: 92px;
    position: absolute;
    top: 33px;
    left: 0; }
  .header_lead {
    padding-left: 120px;
    padding-top: 61px;
    text-align: left;
    line-height: 171.42857%;
    letter-spacing: 0.1em; }
  .header_credit {
    width: 268px;
    position: absolute;
    top: 83px;
    right: 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    letter-spacing: 0.1em; }
    .header_credit .logo {
      width: 145px; }

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .header_logo {
    width: 92px;
    position: absolute;
    top: -5px;
    left: 0; }
  /* IE11 */ }

.footer {
  width: 100%;
  padding: 34px 0 25px;
  box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.35) inset; }
  .footer_link {
    margin-bottom: 22px; }
    .footer_link li {
      font-size: 13px;
      display: inline-block;
      padding-right: 5px;
      margin-right: 5px;
      border-right: 1px solid #ccc;
      letter-spacing: .1em; }
      .footer_link li a,
      .footer_link li a:link,
      .footer_link li a:visited {
        color: #666;
        text-decoration: none;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out; }
      @media screen and (min-width: 768px) {
        .footer_link li a:hover {
          opacity: .5; } }
      .footer_link li:last-child {
        border: none;
        margin-right: 0;
        padding-right: 0; }
  .footer .copyright {
    font-size: 12px;
    color: #999;
    letter-spacing: .1em; }
  @media screen and (max-width: 767px) {
    .footer {
      padding: 25px 0 24px; }
      .footer_link {
        margin-bottom: 17px; }
      .footer .copyright {
        font-size: 10px; } }

#subpage .ttl {
  font-size: 28px;
  line-height: 1;
  letter-spacing: .3em;
  text-indent: .3em;
  padding: 160px 0 25px; }
  @media screen and (max-width: 767px) {
    #subpage .ttl {
      font-size: 18px;
      padding: 40px 0 15px; } }

#subpage .page_contents {
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35);
  padding: 72px 40px 90px;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: .1em;
  text-align: left;
  line-height: 171.42857%; }
  #subpage .page_contents_inner {
    max-width: 800px;
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    #subpage .page_contents {
      padding: 35px 10% 50px;
      font-size: 13px;
      letter-spacing: .05em;
      line-height: 176.92308%; } }

#subpage .lead {
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    #subpage .lead {
      margin-bottom: 18px; } }

#subpage .block {
  margin-bottom: 50px; }
  #subpage .block:last-child {
    margin-bottom: 0; }
  #subpage .block dt {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px; }
  @media screen and (max-width: 767px) {
    #subpage .block {
      margin-bottom: 37px; }
      .page-use .block {
        margin-bottom: 20px; }
        .page-use .block:first-child {
          margin-bottom: 37px; }
      #subpage .block:last-child {
        margin-bottom: 0; }
      #subpage .block dt {
        font-size: 13px; } }

.page_contents.thanks_page {
  padding: 90px 40px 160px; }
  .page_contents.thanks_page .page_contents_inner {
    text-align: center;
    max-width: 600px; }
    .page_contents.thanks_page .page_contents_inner .msg {
      font-size: 14px;
      line-height: 242.85714%;
      letter-spacing: .1em;
      margin-bottom: 90px; }
      .page_contents.thanks_page .page_contents_inner .msg .red {
        color: #c00; }
  .page_contents.thanks_page .top_btn {
    width: 100%;
    max-width: 270px;
    height: 50px;
    margin: 0 auto; }
    .page_contents.thanks_page .top_btn a,
    .page_contents.thanks_page .top_btn a:link,
    .page_contents.thanks_page .top_btn a:visited {
      color: #fff;
      text-decoration: none;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
    @media screen and (min-width: 768px) {
      .page_contents.thanks_page .top_btn a:hover {
        opacity: .5; } }
    .page_contents.thanks_page .top_btn a {
      width: 100%;
      height: 100%;
      display: block;
      background: #000;
      line-height: 50px;
      font-size: 20px;
      letter-spacing: .3em;
      text-indent: .3em; }
      @media screen and (min-width: 768px) {
        .page_contents.thanks_page .top_btn a:hover {
          color: #fff;
          opacity: .5; } }
    @media screen and (max-width: 767px) {
      .page_contents.thanks_page .top_btn {
        height: 50px; }
        .page_contents.thanks_page .top_btn a {
          font-size: 16px;
          line-height: 50px; } }
    .page_contents.thanks_page .top_btn a {
      font-size: 18px; }
  @media screen and (max-width: 767px) {
    .page_contents.thanks_page {
      padding: 35px 9% 50px; }
      .page_contents.thanks_page .page_contents_inner .msg {
        font-size: 13px;
        line-height: 176.92308%;
        letter-spacing: .05em;
        margin-bottom: 45px; } }

.page_top {
  position: fixed;
  right: 0;
  bottom: 120px;
  z-index: 100;
  opacity: 0;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .page_top.show {
    opacity: 1; }
