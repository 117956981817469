@import "default";
@import "var";
@import "util";

body {
	width: 100%;
	background: #fff;
	font-size: 14px;
	color: $color_black;
	text-align: center;

	img {
		max-width: 100%;
	}
}

//header -----------------------------------------------
.header{
	height: 125px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
	margin-bottom: 145px;
	
	&_inner{
		position: relative;
		width: 1120px;
		margin: 0 auto;
	}
	
	&_logo{
		width: 92px;
		position: absolute;
		top: 33px;
		left: 0;
	}
	
	&_lead{
		padding-left: 120px;
		padding-top: 61px;
		text-align: left;
		line-height: percentage(24 / 14);
		letter-spacing: 0.1em;
	}
	
	&_credit{
		width: 268px;
		position: absolute;
		top: 83px;
		right: 0;
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		letter-spacing: 0.1em;
		
		.logo{
			width: 145px;
		}
	}
}

@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, .header_logo { width: 92px; position: absolute; top: -5px; left: 0; } /* IE11 */
}


//footer -----------------------------------------------
.footer {
	width: 100%;
	padding: 34px 0 25px;
	box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.35) inset;

	&_link {
		margin-bottom: 22px;

		li {
			font-size: 13px;
			display: inline-block;
			padding-right: 5px;
			margin-right: 5px;
			border-right: 1px solid #ccc;
			@include txt_link_color(none, #666);
			letter-spacing: .1em;

			&:last-child {
				border: none;
				margin-right: 0;
				padding-right: 0;
			}
		}
	}

	.copyright {
		font-size: 12px;
		color: #999;
		letter-spacing: .1em;
	}

	@include max_screen(767px) {
		padding: 25px 0 24px;

		&_link {
			margin-bottom: 17px;
		}

		.copyright {
			font-size: 10px;
		}
	}
}


// modal ---------------------------------------------------------
#subpage {
	.ttl {
		font-size: 28px;
		line-height: 1;
		letter-spacing: .3em;
		text-indent: .3em;
		padding: 160px 0 25px;

		@include max_screen(767px) {
			font-size: 18px;
			padding: 40px 0 15px;
		}
	}

	.page_contents {
		width: 100%;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35);
		padding: 72px 40px 90px;
		box-sizing: border-box;
		font-size: 14px;
		letter-spacing: .1em;
		text-align: left;
		line-height: percentage(24 / 14);

		&_inner {
			max-width: 800px;
			margin: 0 auto;
		}

		@include max_screen(767px) {
			padding: 35px percentage(75 / 750) 50px;
			font-size: 13px;
			letter-spacing: .05em;
			line-height: percentage(46 / 26);
		}
	}

	.lead {
		margin-bottom: 30px;

		@include max_screen(767px) {
			margin-bottom: 18px;
		}
	}

	.block {
		margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0;
		}

		dt {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 12px;
		}

		@include max_screen(767px) {
			margin-bottom: 37px;

			@at-root .page-use .block {
				margin-bottom: 20px;

				&:first-child {
					margin-bottom: 37px;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			dt {
				font-size: 13px;
			}
		}
	}
}

// -------------------
.page_contents.thanks_page {
	padding: 90px 40px 160px;

	.page_contents_inner {
		text-align: center;
		max-width: 600px;

		.msg {
			font-size: 14px;
			line-height: percentage(34 / 14);
			letter-spacing: .1em;
			margin-bottom: 90px;

			.red {
				color: #c00;
			}
		}
	}

	.top_btn {
		@include txt_btn(270, 50);

		a {
			font-size: 18px;
		}
	}

	@include max_screen(767px) {
		padding: 35px 9% 50px;

		.page_contents_inner {
			.msg {
				font-size: 13px;
				line-height: percentage(46 / 26);
				letter-spacing: .05em;
				margin-bottom: 45px;
			}
		}
	}
}

.page_top{
	position: fixed;
	right: 0;
	bottom: 120px;
	z-index: 100;
	opacity: 0;
	@include transition;

	&.show {
		opacity: 1;
	}
}